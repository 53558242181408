export const GodusTheme = {
  button: {
    defaultProps: {
      color: "brown"
    }
  },
  menu: {
    defaultProps: {
      placement: "bottom",
      offset: 5,
      dismiss: {
        itemPress: true
      },
      animate: {
        unmount: {},
        mount: {}
      },
      lockScroll: false
    },
    styles: {
      base: {
        menu: {
          bg: "bg-white bg-opacity-50 backdrop-blur-sm",
          minWidth: "min-w-[180px]",
          p: "p-1",
          border: "border border-gray-50",
          borderRadius: "rounded-md",
          boxShadow: "shadow-lg shadow-gray-500/10",
          fontFamily: "font-godus",
          fontSize: "text-sm",
          fontWeight: "font-normal",
          color: "text-black",
          overflow: "overflow-auto",
          outline: "focus:outline-none",
          zIndex: "z-[999]"
        },
        item: {
          initial: {
            display: "block",
            width: "w-full",
            pt: "pt-[9px]",
            pb: "pb-2",
            px: "px-3",
            borderRadius: "rounded-md",
            textAlign: "text-start",
            lightHeight: "leading-tight",
            cursor: "cursor-pointer",
            userSelect: "select-none",
            transition: "transition-all",
            bg: "hover:bg-gray-50 hover:bg-opacity-80 focus:bg-gray-50 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80",
            color: "text-black",
            outline: "outline-none"
          }
        }
      }
    }
  }
}