import "@/styles/globals.css"

import type { AppProps } from "next/app"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import React from "react"
import { Analytics } from "@vercel/analytics/react"
import { ThemeProvider } from "@material-tailwind/react"
import { GodusTheme } from "@/components/GodusTheme"
import localFont from "next/font/local"
import classNames from "classnames"

config.autoAddCss = false

const godusFont = localFont({
  src: "../public/fonts/AveriaLibre/2V0aKIcMGZEnV6xygz7eNjESBanIRWbh8g.woff2",
  variable: "--font-godus"
})

type ComponentWithPageLayout = AppProps & {
  Component: AppProps["Component"]
};

export default function App({ Component, pageProps }: ComponentWithPageLayout) {
  return (
    <ThemeProvider value={GodusTheme}>
      <div
        className={classNames(godusFont.variable, "flex flex-1 h-full max-h-full justify-center items-center font-godus")}>
        <Component {...pageProps} />
      </div>
      <Analytics />
    </ThemeProvider>
  )
}
